
import Vue, { PropType } from "vue";
import { mapActions, mapGetters } from "vuex";
import { Branch } from "~/types/branch";
import stringToSlug from "~/utils/strings-transformations";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import {
  CheckIcon,
  XMarkIcon,
  LoadingSpinnerIcon,
} from "~/components/UI/icons";

export default Vue.extend({
  name: "StoresList",
  components: { DefaultButton, CheckIcon, XMarkIcon, LoadingSpinnerIcon },
  props: {
    stores: {
      type: Array as PropType<Branch[]>,
      required: true,
    },
    productCode: {
      type: String,
      required: false,
      default: "",
    },
    buttonText: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      fetchingStock: true,
    };
  },
  computed: {
    ...mapGetters("branch", ["selectedBranchId"]),
    ...mapGetters("stock", ["stockForCollection"]),
  },
  watch: {
    async stores(val) {
      if (this.productCode === "") return;

      this.fetchingStock = true;

      await this.$store.dispatch("stock/getStockForSites", {
        axios: this.$axios,
        products: [this.productCode],
        sites: val.map((store: Branch) => store.id),
      });

      this.fetchingStock = false;
    },
  },
  methods: {
    ...mapActions("branch", ["setStore"]),
    branchSlug(name: string): string {
      return `/branches/${stringToSlug(name)}`;
    },
    stockMessage(storeId: string) {
      if (this.productCode === "" || !this.productCode) return "";
      if (this.fetchingStock) return this.$t("branch.loadingStock");
      return this.hasStock(storeId)
        ? `${this.$t("branch.inStockTodayIn", { qty: this.stockForCollection(this.productCode, storeId) })}`
        : this.$t("branch.outOfStock");
    },
    hasStock(storeId: string) {
      if (!this.productCode) return false;
      const stock = this.stockForCollection(this.productCode, storeId);
      return stock !== "0" || stock === null;
    },
  },
});
