
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import BaseModal from "~/components/UI/modals/BaseModal.vue";
import { Branch } from "~/types/branch";
import StoreFinder from "~/components/branches/StoreFinder.vue";
import StoresList from "~/components/branches/StoresList.vue";

export default Vue.extend({
  name: "CustomerPreferredBranchModal",
  components: {
    BaseModal,
    StoreFinder,
    StoresList,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      domainName: "",
      locationWarning: false,
      stores: [] as Branch[],
    };
  },
  computed: {
    ...mapGetters("branch", ["nearestBranches", "showStockFor"]),
    ...mapGetters("auth", ["getUser"]),
  },
  mounted() {
    this.domainName = window.location.origin;
  },
  methods: {
    ...mapMutations("branch", ["setNearestBranches"]),

    closeModal() {
      this.locationWarning = false;
      this.setNearestBranches([]);
      this.$emit("close");
    },
  },
});
