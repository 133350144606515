
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { isEmpty, always } from "ramda";
import {
  getPostcodeAutocompleteData,
  getDetails,
} from "~/services/woosmap.service";
import { fromToolstationCdnUrl } from "~/utils/fromToolstationCdn";
import { getMyCurrentPosition } from "~/lib/branches/storelocator";
import { getNearestBranches } from "~/services/branch.service";
import { BranchesLocation, GoogleMapPosition } from "~/types/branch";
import { MarkerIcon } from "~/components/UI/icons";

interface StoreFinderData {
  query: string;
  showSuggestions: boolean;
  suggestionsLoading: boolean;
}

export default Vue.extend({
  name: "StoreFinder",
  components: { MarkerIcon },
  props: {
    locationEnabled: {
      type: Boolean,
      required: true,
    },
  },
  data(): StoreFinderData {
    return {
      query: "",
      showSuggestions: false,
      suggestionsLoading: true,
    };
  },
  computed: {
    ...mapGetters("branch", ["nearestBranches", "selectedLocality"]),
    disabled(): boolean {
      return isEmpty(this.query);
    },
  },
  mounted() {
    this.getMyLocationClicked();
  },
  methods: {
    fromToolstationCdnUrl,
    ...mapMutations("branch", ["setNearestBranches", "setSelectedLocality"]),

    search() {
      this.suggestionsLoading = true;
      getPostcodeAutocompleteData(
        this.$store?.state?.branch?.postcodeApiKey,
        this.query,
        this.$config.localeInstance
      )
        .then((response) => {
          this.setNearestBranches(response.localities);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.suggestionsLoading = false;
        });
    },
    localityOptionSelected(locality: any) {
      this.query = locality.description;
      this.setSelectedLocality(locality);
      this.findBranch();
      this.showSuggestions = false;
    },

    findBranch() {
      this.localitySelected(this.selectedLocality);
    },

    async getMyLocationClicked(): Promise<void> {
      this.query = "";
      const position: GoogleMapPosition = await getMyCurrentPosition(navigator);

      if (!position.lat || !position.lng) {
        this.$emit("toggleLocationWarning", true);
        return;
      }

      const payload: BranchesLocation = {
        latitude: position.lat,
        longitude: position.lng,
      };

      getNearestBranches(this.$axios, payload).then((response) =>
        this.$emit("nearesetBranchesUpdated", response.data, position)
      );

      this.$emit("toggleLocationWarning", false);
    },

    async localitySelected({ public_id }: Record<string, any>) {
      const locality = await getDetails(
        this.$store?.state?.branch?.postcodeApiKey,
        public_id
      ).catch(always(null));

      if (!locality?.geometry) return;

      const payload: BranchesLocation = {
        latitude: locality.geometry.location.lat,
        longitude: locality.geometry.location.lng,
      };

      getNearestBranches(this.$axios, payload).then((response) =>
        this.$emit(
          "nearesetBranchesUpdated",
          response.data,
          locality.geometry.location
        )
      );
    },
  },
});
