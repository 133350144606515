import { AllBranches, Branch, GoogleMapPosition } from "~/types/branch";

export const getMyCurrentPosition = async (
  navigator: Navigator
): Promise<GoogleMapPosition> => {
  let location: GoogleMapPosition = {
    lat: null,
    lng: null,
  };

  if (navigator.geolocation) {
    const positionPromise = new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    await positionPromise
      .then((position: any) => {
        if (position?.coords) {
          location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        }
      })
      .catch(() => {});
  }

  return location;
};

export const getInitialStores = (allBranches: AllBranches): Branch[] => {
  const arr: Branch[] = [] as Branch[];

  for (const letter in allBranches) {
    allBranches[letter].forEach((branch: Branch) => {
      arr.push(branch);
    });
  }

  return arr;
};
