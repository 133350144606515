var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-0 text-blue"},[_c('h4',{staticClass:"font-bold text-blue text-size-6 mb-4 store-finder-heading text-left"},[_vm._v("\n    "+_vm._s(_vm.$tc("branch.storeFinderHeading"))+"\n  ")]),_vm._v(" "),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.showSuggestions = false)),expression:"() => (showSuggestions = false)"}],staticClass:"flex flex-col relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],staticClass:"px-2 py-2 w-full placeholder-blue text-sm bg-grey-2 border border-grey-4 text-blue text-[16px] rounded-lg relative",class:{
        '!rounded-bl-none !rounded-br-none':
          _vm.showSuggestions && _vm.nearestBranches.length,
      },attrs:{"id":"address","type":"text","data-testid":"stores-finder-input","placeholder":_vm.$tc('branch.postcodeInputPlaceholder'),"required":""},domProps:{"value":(_vm.query)},on:{"input":[function($event){if($event.target.composing)return;_vm.query=$event.target.value},_vm.search],"focus":function($event){_vm.showSuggestions = true}}}),_vm._v(" "),_c('button',{staticClass:"text-[14px] font-semibold h-max flex items-center mt-2",class:{ 'opacity-20': !_vm.locationEnabled },attrs:{"disabled":!_vm.locationEnabled},on:{"click":function($event){$event.preventDefault();return _vm.getMyLocationClicked.apply(null, arguments)}}},[_c('img',{staticClass:"w-4 h-4 mr-1",attrs:{"src":_vm.fromToolstationCdnUrl(
            'icons/location-crosshairs-solid.svg',
            _vm.$config
          ),"alt":"location-crosshairs-solid-icon","data-testid":"location-crosshairs-solid-icon","loading":"lazy"}}),_vm._v(" "),_c('span',{staticClass:"use-current-location-text"},[_vm._v("\n        "+_vm._s(_vm.$tc("branch.useMyCurrentLocation"))+"\n      ")])]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.nearestBranches.length && _vm.showSuggestions)?_c('ul',{staticClass:"w-full absolute top-[39px] bg-white p-1 border-2 rounded-tl-none rounded-tr-none z-30 rounded-xl max-h-[200px] overflow-y-auto shadow-md"},[_vm._l((_vm.nearestBranches),function(locality,key){return [_c('li',{key:key,staticClass:"flex items-center gap-1 text-left text-sm p-1 w-full cursor-pointer hover:bg-blue-10 border-1 border-white hover:border-grey-3 rounded-md",attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.localityOptionSelected(locality)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.localityOptionSelected(locality)}}},[_c('MarkerIcon',{attrs:{"classes":"shrink-0"}}),_vm._v(" "),_c('a',{staticClass:"text-left text-[14px]"},[_vm._v("\n              "+_vm._s(locality.description)+"\n            ")])],1)]})],2):(
          !_vm.nearestBranches.length &&
          _vm.showSuggestions &&
          _vm.query &&
          !_vm.suggestionsLoading
        )?_c('div',{staticClass:"w-full absolute top-[39px] bg-white p-6 border-2 rounded-tl-none rounded-tr-none z-30 rounded-xl max-h-[200px] overflow-y-auto shadow-md text-size-4 text-center",domProps:{"innerHTML":_vm._s(_vm.$t('branch.noResultsMessage', { query: _vm.query }))}}):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }