var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.stores.length)?_c('div',{staticClass:"text-blue"},[_c('h4',{staticClass:"font-bold text-blue text-size-6 mt-7 mb-3 text-left"},[_vm._v("\n    "+_vm._s(_vm.$tc("branch.storeListHeading"))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"overflow-y-auto h-[350px]"},_vm._l((_vm.stores),function(store,index){return _c('div',{key:index,staticClass:"border-1 p-4 relative",class:index + 1 === _vm.stores.length ? '' : 'border-b-0',attrs:{"data-testid":"store-list"}},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"w-full text-left flex gap-3 items-start justify-between mb-2 md:mb-0",class:{ '!mb-2': !_vm.stockMessage(store.id) }},[_c('Nuxt-Link',{staticClass:"font-semibold cursor-pointer hover:underline",attrs:{"to":_vm.branchSlug(store.name)}},[_vm._v("\n            "+_vm._s(store.name)+"\n          ")]),_vm._v(" "),(_vm.stockMessage(store.id))?_c('div',{staticClass:"p-2 text-[1.3rem] md:text-size-4 font-semibold shrink-0 bg-grey-1 rounded-md flex items-center gap-2"},[_c(_vm.fetchingStock
                  ? 'LoadingSpinnerIcon'
                  : _vm.hasStock(store.id)
                    ? 'CheckIcon'
                    : 'XMarkIcon',{tag:"component",attrs:{"size":"sm","classes":`text-size-4 rounded-full w-5 h-5 text-white p-1 shrink-0 ${_vm.fetchingStock ? 'bg-blue' : _vm.hasStock(store.id) ? 'bg-green-300' : 'bg-red-error'}`}}),_vm._v("\n            "+_vm._s(_vm.stockMessage(store.id))+"\n          ")],1):_c('Nuxt-Link',{staticClass:"underline text-size-2 md_lg:text-size-4 cursor-pointer",attrs:{"to":_vm.branchSlug(store.name)}},[_vm._v("\n            "+_vm._s(_vm.$tc("branch.seeMore"))+"\n          ")])],1),_vm._v(" "),_c('div',{staticClass:"w-full md:w-2/3 text-[13px] text-left"},[_c('p',[_vm._v("\n            "+_vm._s(store.address_text)+"\n          ")])]),_vm._v(" "),_c('div',[_c('DefaultButton',{staticClass:"!w-full md:!max-w-[250px] mt-5",attrs:{"variant":"primary","disabled":_vm.selectedBranchId === store.id ||
              (_vm.productCode !== '' && (!_vm.hasStock(store.id) || _vm.fetchingStock)),"size":"small"},on:{"clickAction":function($event){_vm.setStore(store), _vm.$emit('storeSelected')}}},[_vm._v("\n            "+_vm._s(_vm.buttonText
                ? _vm.buttonText
                : _vm.selectedBranchId === store.id
                  ? _vm.$tc("branch.currentlyFavStore")
                  : _vm.$tc("branch.setStore"))+"\n          ")])],1)])])}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }